dmx.Component('download', {

    initialData: {
        progress: {
            position: 0,
            total: 0,
            percent: 0
        },

        lastError: {
            status: 0,
            message: ''
        }
    },

    attributes: {
        timeout: {
            type: Number,
            default: 0
        },

        url: {
            type: String,
            default: ''
        },

        filename: {
            type: String,
            default: ''
        },
    },

    methods: {
        abort: function() {
            this.abort();
        },

        download: function(url) {
            this.download(url);
        },
    },

    events: {
        done: Event,
        error: Event,
        start: Event,
        progress: ProgressEvent,
    },

    render: function(node) {
        this.xhr = new XMLHttpRequest();
        this.xhr.addEventListener('load', this.onload.bind(this));
        this.xhr.addEventListener('abort', this.onabort.bind(this));
        this.xhr.addEventListener('error', this.onerror.bind(this));
        this.xhr.addEventListener('timeout', this.ontimeout.bind(this));
        this.xhr.addEventListener('progress', this.onprogress.bind(this));
    },

    update: function(props) {
    },

    abort: function() {
        this.xhr.abort();
    },

    download: function(_url) {
        this.url = _url || this.props.url;

        this.xhr.abort();

        this.trigger('start');

        if (this.cors(this.url)) {
            this.xhr.open('GET', this.url);
            this.xhr.responseType = 'blob';
            this.xhr.send();
        } else {
            var a = document.createElement('a');
            a.href = this.url;
            a.download = this.props.filename || this.url.replace(/.*\//, '').replace(/\?.*/, '') || 'download';
            a.rel = 'noopener';
            a.target = '_blank';
            a.dispatchEvent(new MouseEvent('click'));
        }
    },

    reset: function() {
        this.set({
            progress: {
                position: 0,
                total: 0,
                percent: 0
            },
            error: {
                status: 0,
                message: '',
                response: null
            }
        });
    },

    getFilename: function() {
        var header = this.xhr.getResponseHeader('Content-Disposition');
        var match = header && header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        return this.props.filename || (match && match[1] ? match[1].replace(/['"]/g, '') : false) || this.url.replace(/.*\//, '').replace(/\?.*/, '') || 'download';
    },

    onload: function() {
        this.reset();
        this.trigger('done');

        if (this.xhr.status >= 200 && this.xhr.status < 300) {
            var blob = this.xhr.response;
            var a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = this.getFilename();
            a.rel = 'noopener';
            
            setTimeout(function () { URL.revokeObjectURL(a.href); }, 4E4);
            setTimeout(function () { a.dispatchEvent(new MouseEvent('click')); }, 0);
        } else {
            this.set('error', {
                status: this.xhr.status,
                message: this.xhr.statusText
            });
            this.trigger('error');
        }
    },

    onabort: function() {
        this.reset();
        this.trigger('done');
    },

    onerror: function() {
        this.reset();
        this.set('error', {
            status: 0,
            message: 'Failed to download'
        });
        this.trigger('error');
        this.trigger('done');
    },

    ontimeout: function() {
        this.reset();
        this.set('error', {
            status: 0,
            message: 'Download timeout'
        });
        this.trigger('error');
        this.trigger('done');
    },

    onprogress: function(event) {
        var position = event.loaded || event.position;
        var percent =  event.lengthComputable ? Math.ceil(event.loaded / event.total * 100) : 0;

        this.set('progress', {
            position: position,
            total: event.total,
            percent: percent
        });

        this.trigger('progress', {
            lengthComputable: event.lengthComputable,
            loaded: position,
            total: event.total
        });
    },

    cors: function(url) {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', url, false);
        try { xhr.send(); } catch (e) {}
        return xhr.status >= 200 && xhr.status < 300;
    },

    trigger: function(event, data) {
        var dispatch = this.dispatchEvent.bind(this);
        requestAnimationFrame(function() {
            dispatch(event, data);
        });
    }

});